import React from 'react';
import { HStack, Text, Avatar } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../redux/createStore';

export const MiniTeamChatCard: React.FC = () => {
	const { user } = useSelector((state: RootStore) => state.auth);
	const avatars = useSelector((state: RootStore) => state.avatars);

	if (user.workSpace?.teamChat) {
		console.log(avatars[user.workSpace?.teamChat?.id], 'ava');
	}

	return (
		<HStack
			spacing="4px"
			p="0px 16px"
			borderRadius="4px"
			bg="#00000005"
			border="1px solid"
			borderColor="gray.20"
			h="32px">
			<Avatar
				boxSize="14px"
				borderRadius="2px"
				src={
					user.workSpace?.teamChat
						? avatars[user.workSpace?.teamChat?.id]
						: ''
				}
			/>
			<Text color="primary" fontSize="13px" fontWeight="500">
				{user.workSpace?.teamChat?.name}
			</Text>
		</HStack>
	);
};
