import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Flex, FlexProps, HStack, Text } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector, useDispatch } from 'react-redux';
import { RootStore } from '../../../redux/createStore';
import { Icon } from '../../Icon/Icon';
import { Select } from '../../RadixSelect/Select';
import { SelectItem } from '../../RadixSelect/SelectItem';
import { EventsIndicator } from '../../EventsIndicator/EventsIndicator';
import { getBoardTabs } from '../../../pages/Dashboard/constants';
import {
	getUnreadCards,
	getUnansweredCards,
	getMentionedCards,
} from '../../../utils';
import { ApiResponse } from '../../../types';
import { QueryKeys } from '../../../constants';
import { setCardsFilterId } from '../../../redux/reducers/page-navigation-state.reducer';
import { colors } from '../../../theme/colors';
import { useGetBoardCards } from '../../../pages/Dashboard/queries';
import { WorkspaceSelect } from './WorkspaceSelect/WorkspaceSelect';
import { useGetMatchedWorkspaceId } from '../../../hooks';
import styles from './styles.module.css';

interface SidebarCompanyHeaderProps {
	name: string;
	imageLink?: string;
	wrapperProps?: FlexProps;
	showSidebarChatView: boolean;
}

export const SidebarCompanyHeader: React.FC<SidebarCompanyHeaderProps> = ({
	name,
	imageLink,
	wrapperProps,
	showSidebarChatView,
}) => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const workspaceId = useGetMatchedWorkspaceId();

	const { data: cardsData } = useGetBoardCards();

	const { filterId, statusId } = useSelector(
		(state: RootStore) => state.pageNavigationState,
	);
	const chatsCache = useSelector(
		(state: RootStore) => state.telegramState.chats,
	);

	const teamTelegramIds = queryClient.getQueryData<
		ApiResponse<{ telegramUserIds: number[] }>
	>([QueryKeys.GET_WORKSPACE_TELEGRAM_IDS, workspaceId]);

	return (
		<Flex
			w="full"
			pt="6px"
			pb={2}
			justify="space-between"
			align="center"
			{...wrapperProps}>
			{!showSidebarChatView ? (
				<WorkspaceSelect imageLink={imageLink} name={name} />
			) : (
				<RouterLink
					to={`/${workspaceId}`}
					className={styles.BackwardLink}>
					<HStack spacing="4px">
						<Icon name="arrow-left" width="16px" height="16px" />
						<Text
							color="primary"
							fontSize="13px"
							lineHeight="20px"
							fontWeight="500">
							Dashboard
						</Text>
					</HStack>
				</RouterLink>
			)}
			{showSidebarChatView ? (
				<Select
					placeholder="Filter by"
					rootProps={{
						defaultValue: filterId.toString(),
						onValueChange: value =>
							dispatch(setCardsFilterId(+value)),
					}}
					triggerStyles={{
						height: '24px',
						borderWidth: '0px',
						fontWeight: 500,
						width: 'auto',
						color: colors.tertiary,
						borderRadius: '4px',
						paddingLeft: '8px',
						paddingRight: '4px',
						paddingTop: '4px',
						paddingBottom: '4px',
						columnGap: '4px',
					}}
					triggerClassName="HideIcons HoverTransparentLight"
					chevronColor={colors.tertiary}
					chevronSize="16px"
					contentStyle={{
						width: '150px',
					}}>
					<SelectItem
						value="1"
						color="primary"
						style={{
							borderBottomWidth: '1px',
							borderBottomColor: 'gray.15',
							borderBottomLeftRadius: '0px',
							borderBottomRightRadius: '0px',
						}}>
						All Chats
					</SelectItem>
					{getBoardTabs({
						unread:
							getUnreadCards(
								cardsData?.value?.cards || [],
								chatsCache || [],
								statusId,
							).length || 0,

						unanswered:
							getUnansweredCards(
								cardsData?.value?.cards || [],
								chatsCache || [],
								teamTelegramIds?.value?.telegramUserIds || [],
								statusId,
							).length || 0,
						activeTasks:
							cardsData?.value?.cards.filter(
								card =>
									card.status.id === statusId &&
									card.tasksCount,
							).length || 0,
						teamActivity:
							cardsData?.value?.cards.filter(
								card =>
									card.status.id === statusId &&
									card.message?.unreadCount,
							).length || 0,
						mentions:
							getMentionedCards(
								cardsData?.value?.cards || [],
								statusId,
							).length || 0,
					})
						.slice(1)
						.map(option => (
							<SelectItem
								value={`${option.id}`}
								key={option.id}
								color="primary"
								style={{
									position: 'relative',
									paddingRight: '8px',
								}}>
								{option.label}
								<EventsIndicator
									count={option.activity}
									wrapperProps={{
										position: 'absolute',
										top: '50%',
										right: '4px',
										transform: 'translateY(-50%)',
										bg: option.activityBgColor,
									}}
									textProps={{
										color: option.activityTextColor,
									}}
								/>
							</SelectItem>
						))}
				</Select>
			) : null}
		</Flex>
	);
};
