import React from 'react';
import { Tag, TagLabel, TagLabelProps, TagProps } from '@chakra-ui/react';

interface CustomTagProps {
	label: string;
	bgColor: string;
	labelColor: string;
	wrapperProps?: TagProps;
	labelProps?: TagLabelProps;
}

export const CustomTag: React.FC<CustomTagProps> = ({
	label,
	bgColor,
	labelColor,
	wrapperProps,
	labelProps,
}) => {
	return (
		<Tag
			w="auto"
			borderRadius="20px"
			h="20px"
			minH="20px"
			flexShrink={0}
			px={0}
			bg={bgColor}
			{...wrapperProps}>
			<TagLabel
				fontSize="13px"
				lineHeight="20px"
				fontWeight={500}
				px={2}
				color={labelColor}
				{...labelProps}>
				{label}
			</TagLabel>
		</Tag>
	);
};
