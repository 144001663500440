import { useQueryClient } from '@tanstack/react-query';
import { ApiResponse, CardDefaultStatusEnum } from '../../../types';
import { BoardCardItemResponse } from '../types';
import { QueryKeys } from '../../../constants';
import { useGetWorkspaceCardsStatuses } from '../../../queries';
import { useGetMatchedWorkspaceId } from '../../../hooks';

export const useUpdateBoardCardData = () => {
	const queryClient = useQueryClient();
	const { data: statusesData } = useGetWorkspaceCardsStatuses();
	const workspaceId = useGetMatchedWorkspaceId();

	const updateBoardCardData = (cardId: number, statusId: number) => {
		return queryClient.setQueryData<
			ApiResponse<{ cards: BoardCardItemResponse[] }>
		>([QueryKeys.GET_BOARD_CARDS, workspaceId], oldData => {
			if (oldData) {
				const matchedStatus = statusesData?.value.statuses?.find(
					status => status.id === statusId,
				);

				const updatedCards = oldData.value.cards.map(card => {
					if (card.cardId !== cardId) {
						return card;
					}
					if (
						(card.status.typeId === CardDefaultStatusEnum.ARCHIVE ||
							matchedStatus?.typeId ===
								CardDefaultStatusEnum.ARCHIVE) &&
						workspaceId
					) {
						queryClient.invalidateQueries({
							queryKey: [
								QueryKeys.GET_FILTER_COUNTS,
								workspaceId,
							],
						});
						queryClient.invalidateQueries({
							queryKey: [
								QueryKeys.GET_USER_TASKS_COUNT,
								workspaceId,
							],
						});
					}
					return {
						...card,
						status: matchedStatus ? matchedStatus : card.status,
					};
				});
				return {
					...oldData,
					value: {
						cards: updatedCards,
					},
				};
			}
			return oldData;
		});
	};

	return { updateBoardCardData };
};
