/* eslint-disable indent */
import React, { useMemo } from 'react';
import { ListItem, Avatar, chakra, HStack } from '@chakra-ui/react';
import { UseComboboxGetItemPropsReturnValue, Overwrite } from 'downshift';
import { useSelector } from 'react-redux';
import { BoardCardItemResponse } from '../../pages/Dashboard/types';
import { RootStore } from '../../redux/createStore';
import { convertBase64ToBlob } from '../../utils';

interface ICommandProps
	extends Omit<
		Overwrite<
			UseComboboxGetItemPropsReturnValue,
			{
				item: string;
				index: number;
			}
		>,
		'item' | 'index'
	> {
	isHighlighted: boolean;
	data: BoardCardItemResponse;
}

export const Command = React.forwardRef<null, ICommandProps>(
	({ isHighlighted, data, ...rest }, ref) => {
		const cachedData = useSelector(
			(state: RootStore) =>
				state.telegramState.chats[data.chatTelegramId],
		);
		cachedData?.chatAvatarDataUri || '';
		const blobUrl = useMemo(
			() =>
				cachedData?.chatAvatarDataUri || ''
					? URL.createObjectURL(
							convertBase64ToBlob(
								cachedData?.chatAvatarDataUri || '',
							),
						)
					: '',
			[cachedData?.chatAvatarDataUri],
		);
		return (
			<ListItem
				backgroundColor={isHighlighted ? '#0000000A' : undefined}
				ref={ref}
				display="flex"
				borderRadius="4px"
				alignItems="center"
				p="9px 4px 9px 10px"
				justifyContent="space-between"
				{...rest}>
				<HStack spacing="8px">
					<Avatar
						boxSize="14px"
						borderRadius="2px"
						src={blobUrl}
						name={data.cardName}
						sx={{
							'> div': {
								fontSize: '8px',
							},
						}}
					/>
					<chakra.span
						fontSize="13px"
						color="secondary"
						fontWeight={400}>
						{data.cardName}
					</chakra.span>
				</HStack>
				{/* <chakra.span fontSize="13px" color="gray.35" fontWeight={400}>
					⌘1
				</chakra.span> */}
			</ListItem>
		);
	},
);
