import { useQuery } from '@tanstack/react-query';
import API from '../api/api.base';
import { ApiResponse, BaseUserData } from '../types';
import { QueryKeys } from '../constants';
import { useGetWorkspaceId } from '../hooks';

export const useGetWorkspaceMembers = (
	isEnabled?: boolean,
	overrideWorkspaceId?: number,
) => {
	const workspaceId = useGetWorkspaceId();

	return useQuery<ApiResponse<{ members: BaseUserData[] }>>({
		queryKey: [
			QueryKeys.GET_WORKSPACE_MEMBERS,
			overrideWorkspaceId || workspaceId,
		],
		queryFn: () =>
			API.get(
				`/api/workspaces/${overrideWorkspaceId || workspaceId}/team`,
				{},
			),
		refetchOnWindowFocus: false,
		enabled: !!isEnabled,
	});
};
