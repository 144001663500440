import { useMutation, useQueryClient } from '@tanstack/react-query';
import API from '../../../api/api.base';
import { ApiResponse, ApiResponseEmpty } from '../../../types';
import { CreateCardTaskPayload } from '../types';
import { Alerter } from '../../../utils';
import { QueryKeys } from '../../../constants';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../redux/createStore';

export const useCreateCardTask = () => {
	const queryClient = useQueryClient();
	const { id } = useSelector((state: RootStore) => state.auth.user);

	return useMutation<ApiResponseEmpty, unknown, CreateCardTaskPayload>({
		mutationKey: [QueryKeys.CREATE_CARD_TASK],
		mutationFn: payload => {
			const { workspaceId, cardId, ...bodyPayload } = payload;

			return API.post(
				`/api/workspaces/${workspaceId}/cards/${cardId}/tasks/create`,
				bodyPayload,
			);
		},
		onSuccess: (data, payload) => {
			if (!data.success) {
				Alerter.error(
					data?.errors?.[0].message || 'Something went wrong',
				);
				return;
			}

			Alerter.success('Task was successfully created!');

			queryClient.invalidateQueries({
				queryKey: [
					QueryKeys.GET_CARD_TASKS,
					payload.cardId,
					payload.workspaceId,
				],
			});
			queryClient.invalidateQueries({
				queryKey: [QueryKeys.GET_FILTER_COUNTS, payload.workspaceId],
			});
			queryClient.invalidateQueries({
				queryKey: [QueryKeys.GET_BOARD_CARDS, payload.workspaceId],
			});

			if (id !== payload.userId) {
				return;
			}

			queryClient.setQueryData<ApiResponse<number> | undefined>(
				[QueryKeys.GET_USER_TASKS_COUNT, payload.workspaceId],
				oldData => {
					if (oldData) {
						return {
							...oldData,
							value: oldData.value + 1,
						};
					}
					return oldData;
				},
			);
		},
	});
};
