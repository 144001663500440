import React, { useEffect, useMemo, useState } from 'react';
import { Button, Flex, Text, VStack } from '@chakra-ui/react';
import { Requester } from 'jsonrpc-iframe';
import { useSelector } from 'react-redux';
import { ChatItem } from './ChatItem';
import { useSetTeamChat } from '../queries';
import { Loader } from '../../../components';
import { useTelegram } from '../../../services';
import { RootStore } from '../../../redux/createStore';
import { Methods } from '../../../services/telegram/types';
import { useChatAvatars, useGetMatchedWorkspaceId } from '../../../hooks';
import { ApiChatType } from '../../../types';
import { tgChatTypes } from '../../../constants';

interface ModalBodyProps {
	onClose: VoidFunction;
}

export const ModalBody: React.FC<ModalBodyProps> = ({ onClose }) => {
	const tg = useTelegram();
	const workspaceId = useGetMatchedWorkspaceId();
	const { mutateAsync, isPending } = useSetTeamChat();

	const avatars = useSelector((state: RootStore) => state.avatars);
	const { isSynced } = useSelector((state: RootStore) => state.syncState);

	const [isLoaded, setIsLoaded] = useState(false);
	const [selectedChat, setSelectedChat] = useState<{
		id: string;
		title: string;
	} | null>(null);
	const [chats, setChats] = useState<
		{ id: string; title: string; type: ApiChatType }[]
	>([]);

	const chatIds = useMemo(
		() =>
			chats
				.map(chat =>
					tgChatTypes.includes(chat.type) ? +chat.id : null,
				)
				.filter(chat => chat !== null) as number[],
		[chats.length],
	);

	useChatAvatars(chatIds);

	const getTgChats = async (methods: Requester<Methods>) => {
		try {
			setIsLoaded(false);
			//TODO refactor and put it to telegram-api
			const res = await methods.proxy.fetchChats({ limit: 25 });

			if (!res) {
				return;
			}

			const fetchedChats = res.chats.map(chat => ({
				id: chat.id,
				title: chat.title,
				type: chat.type,
			}));

			setChats(fetchedChats);
		} catch (error) {
			console.log('getTgChats error: ', error);
		} finally {
			setIsLoaded(true);
		}
	};
	useEffect(() => {
		if (tg?.methods && isSynced) {
			getTgChats(tg.methods);
		}
	}, [tg?.methods, isSynced]);

	const onChangeSelectedFolders = (id: string, title: string) => {
		if (id === selectedChat?.id) {
			setSelectedChat(null);
			return;
		}
		setSelectedChat({ id, title });
	};

	const onSubmit = async (
		workspaceId: number,
		telegramChatId: number,
		name: string,
	) => {
		try {
			const res = await mutateAsync({
				workspaceId,
				telegramChatId,
				name,
			});

			if (!res.success) {
				return;
			}
			setSelectedChat(null);
			onClose();
		} catch (error) {
			console.log('onSubmit team chat error: ', error);
		}
	};

	return (
		<Flex flexDirection="column" align="start" gap="30px">
			<VStack w="100%" spacing={3}>
				<Text
					fontWeight={600}
					fontSize="20px"
					lineHeight="24px"
					textAlign="center">
					Select your team chat
				</Text>
				<Text textAlign="center" color="secondary">
					Most recent 25 chats are shown
				</Text>
			</VStack>

			{isLoaded ? (
				<React.Fragment>
					<Flex
						flexDirection="column"
						align="start"
						borderRadius="8px"
						borderWidth="1px"
						borderColor="gray.20"
						bg="gray.10"
						w="100%"
						maxH="400px"
						overflowY="auto">
						{chats.map(chat => (
							<ChatItem
								key={chat.id}
								value={chat.id}
								name={chat.title}
								avatarUri={avatars[`${chat.id}`]}
								isChecked={chat.id === selectedChat?.id}
								onCheckedChange={() =>
									onChangeSelectedFolders(chat.id, chat.title)
								}
							/>
						))}
					</Flex>

					<VStack w="100%" spacing={3}>
						<Button
							w="full"
							maxW="200px"
							variant="outline"
							alignSelf="center"
							onClick={() => {
								if (!workspaceId || !selectedChat) {
									return;
								}

								onSubmit(
									workspaceId,
									+selectedChat.id,
									selectedChat.title,
								);
							}}
							boxShadow="0px 2px 4px 0px #0000000A, 0px 0px 4px 0px #00000014"
							isDisabled={!selectedChat}
							isLoading={isPending}>
							Sync with DISE
						</Button>

						<Button
							onClick={onClose}
							w="65%"
							variant="ghost"
							alignSelf="center">
							Continue without sync
						</Button>
					</VStack>
				</React.Fragment>
			) : (
				<Loader
					centerProps={{
						h: '500px',
						justifyContent: 'center',
						w: '100%',
					}}
				/>
			)}
		</Flex>
	);
};
