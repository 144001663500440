import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { ApiResponseEmpty } from '../../../../../types';
import API from '../../../../../api/api.base';
import { QueryKeys } from '../../../../../constants';

export const useMarkChatAsRead = (workspaceId?: number, cardId?: number) => {
	return useMutation<ApiResponseEmpty, unknown>({
		mutationKey: [QueryKeys.MARK_CHAT_AS_READ],
		mutationFn: () =>
			API.post(
				`/api/workspaces/${workspaceId}/cards/${cardId}/chat/read`,
				{ dateTime: dayjs.utc().format() },
			),
	});
};
