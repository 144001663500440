import React, { useMemo } from 'react';
import {
	Box,
	Heading,
	Button,
	Flex,
	chakra,
	useDisclosure,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { Task, Icon, Loader, EmptyItemsText } from '../../../../components';
import { useGetCardTasks, useUpdateCardTaskStatus } from '../../queries';
import {
	ApiResponse,
	CardDefaultStatusEnum,
	CardTaskStatusEnum,
} from '../../../../types';
import { Alerter } from '../../../../utils';
import { TaskPopup } from '../../components';
import { RootStore } from '../../../../redux/createStore';
import { BoardCardItemResponse } from '../../../Dashboard/types';
import { QueryKeys } from '../../../../constants';
import { colors } from '../../../../theme/colors';

interface TasksProps {
	workspaceId?: number;
	cardId: number;
}

export const Tasks: React.FC<TasksProps> = ({ workspaceId, cardId }) => {
	const { state } = useLocation();
	const { isOpen, onClose, onToggle } = useDisclosure();
	const queryClient = useQueryClient();
	const { mutateAsync: updateTaskStatus } = useUpdateCardTaskStatus();
	const { data, isLoading } = useGetCardTasks(workspaceId, cardId);

	const { chatTelegramId } = state || {};

	const avatars = useSelector((state: RootStore) => state.avatars);
	const { id: currentUserId, workSpace } = useSelector(
		(state: RootStore) => state.auth.user,
	);

	const cardsData = queryClient.getQueryData<
		ApiResponse<{ cards: BoardCardItemResponse[] }>
	>([QueryKeys.GET_BOARD_CARDS, workspaceId]);

	const isCurrentCardArchived = useMemo(() => {
		const cardData = cardsData?.value?.cards?.find(
			card => card.cardId === cardId,
		);
		return cardData?.status?.typeId === CardDefaultStatusEnum.ARCHIVE;
	}, [cardsData, cardId]);

	const onUpdateTaskStatus = async (
		cardTaskId: number,
		status: CardTaskStatusEnum,
		isCardArchived: boolean,
		telegramUsersIds: number[],
		workspaceId?: number,
	) => {
		try {
			if (!workspaceId) {
				return Alerter.error('Workspace is missing');
			}

			await updateTaskStatus({
				bodyPayload: {
					workspaceId,
					status,
					cardTaskId,
					cardId,
					telegramUsersIds,
				},
				isCardArchived,
			});
		} catch (error) {
			console.log('onUpdateTaskStatus: ', error);
		}
	};

	const { otherTasks, otherTasksCount, yourTasks, yourTasksCount } =
		data?.value || {};

	if (isLoading) {
		return <Loader centerHeight="calc(100vh - 105px)" />;
	}

	return (
		<Box p="24px" overflowY="auto" maxH="calc(100vh - 105px)">
			<Flex alignItems="center" justify="space-between" mb="16px">
				<Heading color="primary" fontSize="16px" fontWeight="600">
					Your tasks
					<chakra.span color="gray.35" ml={2}>
						{yourTasksCount || 0}
					</chakra.span>
				</Heading>
				<TaskPopup
					isOpen={isOpen}
					onClose={onClose}
					onToggle={onToggle}
					chatTelegramId={chatTelegramId}
					cardId={cardId}>
					<Button
						variant="outline"
						height="24px"
						shadow="none"
						color="secondary"
						px="8px"
						py="3px"
						borderRadius="4px"
						iconSpacing={1}
						leftIcon={
							<Icon
								name="plus"
								width="14"
								height="14"
								color={colors.secondary}
							/>
						}
						isActive={isOpen}
						onClick={onToggle}>
						Add task
					</Button>
				</TaskPopup>
			</Flex>
			{yourTasks?.length ? (
				<Box
					borderColor="gray.20"
					borderRadius="8px"
					background="gray.10"
					borderWidth="1px"
					overflowY="auto">
					{yourTasks.map(task => (
						<Task
							key={task.id}
							onUpdateTaskStatus={(status, telegramUsersIds) =>
								onUpdateTaskStatus(
									task.id,
									status,
									isCurrentCardArchived,
									telegramUsersIds,
									workspaceId,
								)
							}
							includeActionsMenu={true}
							chatTelegramId={chatTelegramId}
							cardId={cardId}
							{...task}
						/>
					))}
				</Box>
			) : (
				<EmptyItemsText text="There are no tasks at the moment" />
			)}

			{otherTasks?.length ? (
				<Box mt={6}>
					<Heading color="primary" fontSize="16px" fontWeight="600">
						Other tasks
						{otherTasksCount ? (
							<chakra.span color="gray.35" ml={2}>
								{otherTasksCount}
							</chakra.span>
						) : null}
					</Heading>
					<Box
						mt={4}
						borderColor="gray.20"
						borderRadius="8px"
						background="gray.10"
						borderWidth="1px"
						overflowY="auto">
						{otherTasks.map(task => (
							<Task
								key={task.id}
								userAvatarUri={
									avatars[`${task.telegramUserId}`]
								}
								includeActionsMenu={
									workSpace?.isAdmin ||
									currentUserId === task.authorUserId
								}
								cardId={cardId}
								chatTelegramId={chatTelegramId}
								{...task}
							/>
						))}
					</Box>
				</Box>
			) : null}
		</Box>
	);
};
