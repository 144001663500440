export const convertBase64ToBlob = (base64Data?: string) => {
	if (!base64Data) {
		return new Blob();
	}
	const base64WithoutPrefix = base64Data.split(',')[1]; // Remove data URI prefix
	const byteCharacters = atob(base64WithoutPrefix);
	const byteArrays = [];
	for (let offset = 0; offset < byteCharacters.length; offset += 512) {
		const slice = byteCharacters.slice(offset, offset + 512);
		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}
	return new Blob(byteArrays);
};
