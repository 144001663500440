import React from 'react';
import { Tab, TabProps } from '@chakra-ui/react';
import { Icon } from '../Icon/Icon';

interface TabWithIconProps extends TabProps {
	label: string;
	iconName: string;
}

export const TabWithIcon: React.FC<TabWithIconProps> = ({
	label,
	iconName,
	...props
}) => {
	return (
		<Tab
			p={2}
			gap="4px"
			color="gray.50"
			fontSize="13px"
			fontWeight="500"
			userSelect="none"
			_selected={{ color: 'gray.60' }}
			{...props}>
			<Icon name={iconName} height="16" width="16" />
			{label}
		</Tab>
	);
};
