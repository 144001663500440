import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import API from '../api/api.base';
import { ApiResponse } from '../types';
import { QueryKeys } from '../constants';
import { RootStore } from '../redux/createStore';
import { getMillisecondsUntilNextNineAM } from '../utils';
import { useGetMatchedWorkspaceId } from '../hooks';

export const useGetNotificationsCount = () => {
	const workspaceId = useGetMatchedWorkspaceId();

	const { isOnboarded } = useSelector((state: RootStore) => state.auth.user);

	const refetchInterval = getMillisecondsUntilNextNineAM();

	return useQuery<ApiResponse<{ count: number }>>({
		queryKey: [QueryKeys.GET_NOTIFICATIONS_COUNT, workspaceId],
		queryFn: () =>
			API.get(`/api/workspaces/${workspaceId}/notifications/count`, {}),
		refetchOnWindowFocus: false,
		refetchInterval,
		enabled: !!workspaceId && isOnboarded,
	});
};
