import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import API from '../../../api/api.base';
import { ApiResponse } from '../../../types';
import { BoardCardItemResponse } from '../types';
import { QueryKeys } from '../../../constants';
import { RootStore } from '../../../redux/createStore';
import { useGetMatchedWorkspaceId } from '../../../hooks';

export const useGetBoardCards = () => {
	const workspaceId = useGetMatchedWorkspaceId();
	const { isOnboarded } = useSelector((state: RootStore) => state.auth.user);

	return useQuery<ApiResponse<{ cards: BoardCardItemResponse[] }>>({
		queryKey: [QueryKeys.GET_BOARD_CARDS, workspaceId],
		queryFn: () => API.get(`/api/workspaces/${workspaceId}/cards`, {}),
		refetchOnWindowFocus: false,
		enabled: !!workspaceId && isOnboarded,
		refetchInterval: 30 * 1000, //refetch data every 30s
	});
};
