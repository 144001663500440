import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import API from '../api/api.base';
import { sleep } from '../utils';
import { useExchangeOtpCode } from './useExchangeOtpCode';
import { ApiResponse } from '../types';
import { QueryKeys } from '../constants';
import { setExchangingCodeLoading } from '../redux/reducers/login-flow.reducer';

export const useGetOtpCode = (sendCodeToBot: (val: string) => void) => {
	const exchangeOtpCodeMutation = useExchangeOtpCode();
	const dispatch = useDispatch();

	return useMutation<ApiResponse<{ otpCode: string }>, unknown, number>({
		mutationKey: [QueryKeys.GET_OTP_CODE],
		mutationFn: payload =>
			API.post('/api/auth/otp', {
				telegramUserId: payload,
			}),
		onSuccess(data, variables) {
			if (data.success) {
				sendCodeToBot(data.value.otpCode);
				sleep(3000).then(() => {
					exchangeOtpCodeMutation.mutate({
						telegramUserId: +(variables || 0),
						otpCode: data.value.otpCode,
					});
				});
			} else {
				dispatch(setExchangingCodeLoading(false));
			}
		},
		onMutate() {
			dispatch(setExchangingCodeLoading(true));
		},
		onError() {
			dispatch(setExchangingCodeLoading(false));
		},
	});
};
