import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import API from '../api/api.base';
import { ApiResponseEmpty } from '../types';
import { setUserTimeZone } from '../redux/reducers/auth.reducer';

export const useSetUserTimeZone = () => {
	const dispatch = useDispatch();

	return useMutation<ApiResponseEmpty, unknown, string>({
		mutationFn: timeZoneId =>
			API.post('/api/users/timezone', {
				timeZoneId,
			}),
		onSuccess: (data, timeZoneId) => {
			if (!data.success) {
				return;
			}
			dispatch(setUserTimeZone(timeZoneId));
		},
	});
};
