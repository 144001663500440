import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { RootStore } from '../redux/createStore';
import API from '../api/api.base';
import { ApiResponse } from '../types';
import { QueryKeys } from '../constants';
import { useGetWorkspaceId } from '../hooks';

export const useGetFilterCounts = () => {
	const workspaceId = useGetWorkspaceId();
	const { isOnboarded } = useSelector((state: RootStore) => state.auth.user);

	return useQuery<ApiResponse<{ tasksCount: number; unreadCount: number }>>({
		queryKey: [QueryKeys.GET_FILTER_COUNTS, workspaceId],
		queryFn: () =>
			API.get(`/api/workspaces/${workspaceId}/filters/count`, {}),
		refetchOnWindowFocus: false,
		enabled: !!workspaceId && isOnboarded,
		refetchInterval: 1000 * 30, //refetch data every 30s
	});
};
