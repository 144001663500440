import { useMutation, useQueryClient } from '@tanstack/react-query';
import API from '../api/api.base';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAuthStatus } from '../redux/reducers/auth.reducer';
import {
	setShowLoginFlow,
	setTelegramConnected,
	setExchangingCodeLoading,
} from '../redux/reducers/login-flow.reducer';
import { ApiResponse, ExchangeResponse } from '../types';
import { QueryKeys } from '../constants';
import { Alerter } from '../utils';
import { clearTgDataCache } from '../redux/reducers/telegram-state.reducer';

export const useExchangeOtpCode = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	return useMutation<
		ApiResponse<ExchangeResponse>,
		unknown,
		{
			telegramUserId: number;
			otpCode: string;
		}
	>({
		mutationKey: [QueryKeys.EXCHANGE_OTP_CODE],
		mutationFn: payload => API.post('/api/auth/exchange', payload),
		onSuccess(data) {
			if (data.success) {
				dispatch(setAuthStatus(data.value));
				queryClient.invalidateQueries({
					queryKey: [QueryKeys.GET_WORKSPACES],
				});
				dispatch(setShowLoginFlow(false));
				dispatch(setTelegramConnected({ isConnected: false }));
				dispatch(clearTgDataCache());
				navigate('/select-workspace');
			} else {
				Alerter.error(
					'Login attempt was not successful. Please try again',
				);
				dispatch(setTelegramConnected({ isConnected: false }));
			}
		},
		onSettled() {
			dispatch(setExchangingCodeLoading(false));
		},
	});
};
