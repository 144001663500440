import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootStore } from './redux/createStore';
import { browserRouter } from './browserRouter';

export const AppRoutes: React.FC = () => {
	const { user } = useSelector((state: RootStore) => state.auth);

	return (
		<RouterProvider
			router={browserRouter(!!user?.workSpace?.isAdmin, !!user)}
		/>
	);
};
