import { QueryClient } from '@tanstack/react-query';
import { TelegramWindowContextType } from '../context';
import { tgChatTypes } from '../../../constants';

export const fetchTgChat = async (
	queryClient: QueryClient,
	tg: TelegramWindowContextType,
	chatId: number,
) => {
	const getTgChatData = async () => {
		try {
			const chatInfo = await tg.custom.proxy.getChatById(chatId);

			if (!chatInfo) {
				throw new Error('chat information is missing');
			}

			if (
				!chatInfo.chatFullInfo &&
				chatInfo.chatShortInfo?.type &&
				tgChatTypes.includes(chatInfo.chatShortInfo.type)
			) {
				const chatFullInfo = await tg.methods.proxy.fetchFullChat({
					id: chatId.toString(),
					type: chatInfo.chatShortInfo.type,
					title: chatInfo.chatShortInfo?.title || '',
					accessHash: chatInfo.chatShortInfo?.accessHash,
				});

				if (chatFullInfo) {
					return {
						chatShortInfo: chatInfo.chatShortInfo,
						chatFullInfo: chatFullInfo.fullInfo,
					};
				}
			}

			return chatInfo;
		} catch (error) {
			console.log('getTgChatData error: ', error);
			throw error;
		}
	};

	return queryClient.fetchQuery({
		queryKey: ['tg-api-chat-info', chatId],
		queryFn: getTgChatData,
		retryDelay: 1000,
		retry(failureCount) {
			if (failureCount >= 5) {
				console.error(`fetchTgChat - no chat ${chatId} data found`);
				return false;
			}
			return true;
		},
	});
};
