import React, { CSSProperties, PropsWithChildren } from 'react';
import * as RadixSelect from '@radix-ui/react-select';
import { RootProps } from '@radix-ui/themes/dist/esm/components/select.js';
import { Icon } from '../Icon/Icon';
import classNames from 'classnames';

import './styles.css';

interface ISelectProps {
	placeholder: string;
	rootProps?: RootProps;
	triggerStyles?: CSSProperties;
	contentStyle?: CSSProperties;
	chevronColor?: string;
	portalProps?: RadixSelect.PortalProps;
	valueProps?: RadixSelect.SelectValueProps;
	removePortal?: boolean;
	triggerClassName?: string;
	chevronSize?: string;
}

export const Select: React.FC<PropsWithChildren<ISelectProps>> = ({
	placeholder,
	children,
	rootProps,
	triggerStyles,
	contentStyle,
	chevronColor,
	portalProps,
	valueProps,
	triggerClassName,
	removePortal = false,
	chevronSize = '15px',
}) => {
	const renderContent = () => (
		<RadixSelect.Content
			className="SelectContent"
			position="popper"
			style={contentStyle}>
			<RadixSelect.ScrollUpButton className="SelectScrollButton">
				<Icon name="radix-chevron-up" height="15" width="15" />
			</RadixSelect.ScrollUpButton>
			<RadixSelect.Viewport className="SelectViewport">
				{children}
			</RadixSelect.Viewport>
			<RadixSelect.ScrollDownButton className="SelectScrollButton">
				<Icon name="radix-chevron-down" height="15" width="15" />
			</RadixSelect.ScrollDownButton>
		</RadixSelect.Content>
	);

	return (
		<RadixSelect.Root {...rootProps}>
			<RadixSelect.Trigger
				className={classNames('SelectTrigger', triggerClassName)}
				style={triggerStyles}>
				<RadixSelect.Value placeholder={placeholder} {...valueProps} />
				<RadixSelect.Icon className="SelectIcon">
					<Icon
						name="chevron-down"
						height={chevronSize}
						width={chevronSize}
						color={chevronColor}
					/>
				</RadixSelect.Icon>
			</RadixSelect.Trigger>
			{removePortal ? (
				renderContent()
			) : (
				<RadixSelect.Portal {...portalProps}>
					{renderContent()}
				</RadixSelect.Portal>
			)}
		</RadixSelect.Root>
	);
};
