import { useQuery } from '@tanstack/react-query';
import API from '../api/api.base';
import { ApiResponse } from '../types';
import { QueryKeys } from '../constants';
import { useGetMatchedWorkspaceId } from '../hooks';

export const useGetWorkspaceTelegramIds = () => {
	const workspaceId = useGetMatchedWorkspaceId();

	return useQuery<ApiResponse<{ telegramUserIds: number[] }>>({
		queryKey: [QueryKeys.GET_WORKSPACE_TELEGRAM_IDS, workspaceId],
		queryFn: () =>
			API.get(`api/workspaces/${workspaceId}/team/telegram-ids`, {}),
		refetchOnWindowFocus: false,
		enabled: !!workspaceId,
	});
};
