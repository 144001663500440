import React, { useMemo } from 'react';
import { Flex, Text, Avatar, HStack, Box } from '@chakra-ui/react';
import { Icon } from '../../../../../components';
import { CardActivityItem } from '../../../types';
import {
	getActivityIconByTypeId,
	getActivitySubjectName,
	convertBase64ToBlob,
	getViewDateByFormat,
} from '../../../../../utils';
import { RootStore } from '../../../../../redux/createStore';
import { useSelector } from 'react-redux';
import { BaseMessageActions } from './Message/BaseMessageActions';

interface ActionProps extends CardActivityItem {
	cardId: number;
	workspaceId: number;
	messageId: number;
	currentUserId: number;
	isAdmin?: boolean;
}

export const Action: React.FC<ActionProps> = ({
	// id,
	activityTypeId,
	authorInfo,
	content,
	createdAt,
	operation,
	subjectInfo,
	cardId,
	workspaceId,
	messageId,
	currentUserId,
	isAdmin,
}) => {
	const avatars = useSelector((state: RootStore) => state.avatars);

	const iconName = getActivityIconByTypeId(activityTypeId);
	const time = getViewDateByFormat(createdAt, 'hh:mm A');
	const subjectName = getActivitySubjectName(subjectInfo);
	const authorName = getActivitySubjectName(authorInfo);

	const isTask = activityTypeId === 6;

	const avatarUrl = useMemo(() => {
		const userId = isTask
			? subjectInfo?.telegramUserId
			: authorInfo?.telegramUserId;

		const avatarUri = userId ? avatars[`${userId}`] : '';

		const avatar = avatarUri
			? URL.createObjectURL(convertBase64ToBlob(avatarUri))
			: '';
		return avatar;
	}, [subjectInfo, authorInfo, isTask, avatars]);

	const actionsMenuAccessCondition =
		currentUserId === authorInfo?.id ||
		currentUserId === subjectInfo?.id ||
		isAdmin;

	return (
		<Flex
			gap="4px"
			justifyContent="center"
			position="relative"
			wrap="wrap"
			px="20%"
			_hover={{
				'.action': {
					visibility: 'visible',
					opacity: 1,
					transform: 'translateY(-50%) translateX(0)',
				},
				'.activity-time': {
					visibility: actionsMenuAccessCondition
						? 'hidden'
						: 'visible',
					opacity: actionsMenuAccessCondition ? 0 : 1,
				},
			}}>
			<HStack bg="transparentLight.5" borderRadius="8px" padding="0 4px">
				<Icon
					name={iconName}
					height="14"
					width="14"
					style={{ flexShrink: 0 }}
				/>
				<Text
					color="secondary"
					fontSize="13px"
					fontWeight="400"
					noOfLines={1}>
					{content}
				</Text>
			</HStack>
			<Text color="secondary" fontSize="13px" fontWeight="400">
				{operation}
			</Text>
			<HStack spacing="3px">
				<Avatar boxSize="14px" src={avatarUrl || ''} />
				<Text color="primary" fontSize="13px" fontWeight="400">
					{isTask ? subjectName : authorName}
				</Text>
			</HStack>
			<Box position="absolute" right="0" top="0">
				<Text
					className="activity-time"
					visibility="visible"
					opacity={1}
					transition="visibility 0.4s ease-in-out, opacity 0.4s ease-in-out"
					color="#919EAB"
					fontSize="12px"
					fontWeight="400"
					flexShrink={0}>
					{time}
				</Text>
				{actionsMenuAccessCondition ? (
					<BaseMessageActions
						isAction={true}
						cardId={cardId}
						workspaceId={workspaceId}
						messageId={messageId}
					/>
				) : null}
			</Box>
		</Flex>
	);
};
