export enum QueryKeys {
	GET_CARD_TASKS = 'card-tasks',
	GET_TEMPLATES = 'templates',
	GET_BOARD_CARDS = 'board-cards',
	GET_CURRENT_USER = 'get-current-user',
	GET_WORKSPACE_CARDS_STATUSES = 'workspace-cards-statuses',
	GET_ALL_CURRENT_USER_TASKS = 'get-all-current-user-tasks',
	GET_ALL_TASKS_OTHER_USERS = 'get-all-tasks-other-users',
	GET_CHAT_MESSAGES = 'get-chat-messages',
	GET_CARD_ACTIVITIES_OPTIONS = 'get-card-activities-options',
	GET_CARD_ACTIVITIES_LIST = 'get-card-activities-list',
	GET_WORKSPACE_MEMBERS = 'workspace-members',
	GET_USER_TASKS_COUNT = 'get-user-tasks-count',
	GET_WORKSPACE_TELEGRAM_IDS = 'get-workspace-telegram-ids',
	GET_TG_FOLDERS = 'get-tg-folders',
	GET_FILTER_COUNTS = 'get-filter-counts',
	GET_WORKSPACE_INFO = 'get-workspace-info',
	GET_WORKSPACES = 'get-workspaces',
	GET_CARD_REMINDERS = 'get-card-reminders',
	GET_NOTIFICATIONS = 'get-notifications',
	GET_NOTIFICATIONS_COUNT = 'get-notifications-count',
	GET_ACTIVE_STAGES = 'get-active-stages',
	// mutation keys below
	CREATE_CARD_TASK = 'create-card-task',
	CREATE_CARD_ACTIVITY = 'create-card-activity',
	CREATE_WORKSPACE = 'create-workspace-mutation',
	INVITE_MEMBERS_TO_WORKSPACE = 'create-invite-members-to-workspace-mutation',
	SYNC_FOLDERS = 'sync-folders-mutation',
	UPDATE_TASK_STATUS = 'update-task-status',
	CREATE_TEMPLATE = 'create-template-mutation',
	DELETE_TEMPLATE = 'delete-template-mutation',
	UPDATE_TEMPLATE = 'update-template-mutation',
	EXCHANGE_OTP_CODE = 'exchange-otp-code-mutation',
	GET_OTP_CODE = 'get-otp-code-mutation',
	LOGOUT = 'logout',
	UPDATE_WORKSPACE_CARD_STATUS = 'update-workspace-card-status',
	UPDATE_CARD_TRACK_STATUS = 'update-card-track-status',
	SEND_MESSAGE = 'send-message',
	MARK_CHAT_AS_READ = 'mark-chat-as-read',
	SET_TEAM_CHAT = 'set-team-chat',
	UPDATE_ARCHIVE_VISIBILITY = 'update-archive-visibility',
	REMOVE_CARD_ACCESS = 'remove-card-access',
	DELETE_USER_FROM_WORKSPACE = 'delete-user-from-workspace',
	UPDATE_WORKSPACE_INFO = 'update-workspace-info',
	UPDATE_WORKSPACE_LOGO = 'update-workspace-logo',
	UPDATE_TASK = 'update-task',
	DELETE_TASK = 'delete-task',
	DELETE_MESSAGE = 'delete-message',
	SET_WORKSPACE = 'set-workspace',
	SAVE_STAGES_CHANGES = 'save-stages-changes',
}
