export const colors = {
	black: '#000',
	white: '#fff',
	primary: '#333333',
	secondary: '#666666',
	tertiary: '#999999',
	mainSurface: '#f5f5f5',
	midnightBlack: {
		10: '#0D0D0F',
	},
	yellow: {
		DEFAULT: '#ffd338',
		80: '#2e2a1a',
		70: '#453d1e',
		60: '#746224',
		50: '#b99b2e',
		40: '#ffe074',
		30: '#ffedaf',
		20: '#fff6d7',
		10: '#fffbeb',
	},
	green: {
		DEFAULT: '#55ef3c',
		80: '#1d2d1b',
		70: '#23421e',
		60: '#2a5822',
		50: '#42ae31',
		40: '#88f477',
		30: '#ccfac5',
		20: '#ddfcd8',
		10: '#eefdec',
	},
	turquoise: {
		DEFAULT: '#15de8f',
		80: '#172b23',
		70: '#173f2f',
		60: '#166747',
		50: '#16a26b',
		40: '#5be8b1',
		30: '#a1f2d2',
		20: '#d0f8e9',
		10: '#e8fcf4',
	},
	sky: {
		DEFAULT: '#00e0ff',
		80: '#152b2e',
		70: '#123f45',
		60: '#0e6874',
		50: '#07a4b9',
		40: '#4de9ff',
		30: '#99f3ff',
		20: '#ccf9ff',
		10: '#e5fcff',
	},
	blue: {
		DEFAULT: '#007aff',
		80: '#171e2c',
		70: '#172642',
		60: '#18356d',
		50: '#184bad',
		40: '#5e90f2',
		30: '#a3c0f8',
		20: '#d1dffb',
		10: '#e8effd',
	},
	purple: {
		DEFAULT: '#915ffd',
		80: '#231e2e',
		70: '#2f2545',
		60: '#483473',
		50: '#6c49b8',
		40: '#b28ffe',
		30: '#d3bffe',
		20: '#e9dfff',
		10: '#f4efff',
	},
	pink: {
		DEFAULT: '#f54bd0',
		80: '#2d1c29',
		70: '#43213c',
		60: '#702c61',
		50: '#b23b98',
		40: '#f881de',
		30: '#fbb7ec',
		20: '#fddbf6',
		10: '#feedfa',
	},
	red: {
		DEFAULT: '#f83e3e',
		90: '#ff385c',
		80: '#2d1b1b',
		70: '#441f1f',
		60: '#712727',
		50: '#b43232',
		40: '#fa7878',
		30: '#fcb2b2',
		20: '#fed8d8',
		10: '#feecec',
	},
	orange: {
		DEFAULT: '#ff7222',
		80: '#2e2018',
		70: '#452919',
		60: '#743b1b',
		50: '#b9571f',
		40: '#ff9c64',
		30: '#ffc7a7',
		20: '#ffe3d3',
		10: '#fff1e9',
	},
	blueAccent: {
		90: '#141a25',
		85: '#151d2e',
		80: '#152037',
		75: '#16233f',
		70: '#17294a',
		60: '#18356d',
		40: '#a3c0f8',
		35: '#c8d9fb',
		25: '#dae6fc',
		20: '#e2ecfd',
		15: '#edf2fe',
		10: '#f5f9fd',
	},
	gray: {
		100: '#000000',
		90: '#141414',
		85: '#171717',
		80: '#1b1b1b',
		75: '#1d1d1d',
		70: '#222222',
		65: '#292929',
		60: '#333333',
		55: '#4c4c4c',
		50: '#666666',
		45: '#818181',
		40: '#999999',
		35: '#b3b3b3',
		30: '#cccccc',
		25: '#d6d6d6',
		20: '#ebebeb',
		15: '#f1f1f1',
		10: '#fcfcfc',
		0: '#ffffff',
	},
	transparentLight: {
		100: '#000000CC', // 0.8
		95: '#000000C2', // 0.76
		90: '#000000B8', // 0.72
		85: '#000000AD', // 0.68
		80: '#000000A3', // 0.64
		75: '#00000099', // 0.6
		70: '#0000008F', // 0.56
		65: '#00000085', // 0.52
		60: '#0000007A', // 0.48
		55: '#00000070', // 0.44
		50: '#00000066', // 0.4
		45: '#0000005C', // 0.36
		40: '#00000052', // 0.32
		35: '#00000047', // 0.28
		30: '#0000003D', // 0.24
		25: '#00000033', // 0.2
		20: '#00000029', // 0.16
		15: '#0000001F', // 0.12
		10: '#00000014', // 0.08
		5: '#0000000A', // 0.04
	},
	lime: {
		30: '#B5D146',
		20: '#CAE94D',
		10: '#DDFF55',
	},
	darkBlue: {
		10: '#85AEFF',
	},
};
