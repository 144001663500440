import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useInterval } from 'usehooks-ts';
import { TG_CONFIG } from '../../tg-config';
import { useTelegram } from '../../services';
import {
	useThrottleCallback,
	useGetMatchedCardId,
	useGetCardById,
} from '../../hooks';
import { useLogout } from '../../queries';
import { RootStore } from '../../redux/createStore';
import {
	CardDefaultStatusEnum,
	TGStateAuthenticatedUser,
	TGStateUnauthenticatedUser,
	TGSyncState,
} from '../../types';
import {
	setShowTelegramModal,
	setShowLoginFlow,
	setTelegramConnected,
} from '../../redux/reducers/login-flow.reducer';
import { setSyncState } from '../../redux/reducers/sync-state.reducer';
import { colors } from '../../theme/colors';
import { ErrorPlaceholder } from './ErrorPlaceholder';
import { limitedAccessCardsBoxStyles } from './constants';

export const TelegramHiddenFrame: React.FC = () => {
	const tg = useTelegram();
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const cardId = useGetMatchedCardId();

	const { mutateAsync: logoutMutation } = useLogout();

	const { token, user } = useSelector((state: RootStore) => state.auth);
	const { showTelegramLoginModal } = useSelector(
		(state: RootStore) => state.loginFlow,
	);

	const isChat = pathname.includes('/chat') && user.workSpace;

	const matchedCard = useGetCardById(cardId);

	const isTeamChat = useMemo(
		() => user?.workSpace?.teamChat?.cardId === cardId,
		[user, cardId],
	);

	const isCardArchived = useMemo(() => {
		if (isTeamChat) {
			return false;
		}
		if (!matchedCard) {
			return true;
		}
		if (!isChat) {
			return false;
		}

		return matchedCard.status?.typeId === CardDefaultStatusEnum.ARCHIVE;
	}, [isChat, matchedCard, isTeamChat]);

	const chatTelegramId = useMemo(() => {
		return isTeamChat && user.workSpace?.teamChat?.id
			? user.workSpace.teamChat.id
			: !matchedCard?.chatTelegramId || !isChat
				? 0
				: matchedCard.chatTelegramId;
	}, [isChat, matchedCard, isTeamChat, user.workSpace?.teamChat?.id]);

	const cachedData = useSelector(
		(state: RootStore) => state.telegramState.chats[chatTelegramId],
	);

	const { accessToken, refreshToken } = token;

	const [statusNotSucceeded, setStatusNotSucceeded] = useState(false);
	const [statusConnected, setStatusConnected] = useState(false);

	const statusCheckCount = useRef(0);

	const handleAuthStateChange = async (
		state: TGStateUnauthenticatedUser | TGStateAuthenticatedUser,
	) => {
		if (!state.authed && accessToken && refreshToken) {
			await logoutMutation({
				accessToken,
				refreshToken,
			});
			return;
		}

		if (state.authed && state.userId && showTelegramLoginModal) {
			dispatch(setShowTelegramModal(false));
			dispatch(setShowLoginFlow(true));
			dispatch(
				setTelegramConnected({
					isConnected: true,
					userId: state.userId,
				}),
			);
		}
	};

	const throttledCallback = useThrottleCallback(handleAuthStateChange, 1);

	useEffect(() => {
		const unSub = tg?.events.subscribe(
			'authStateChanged',
			throttledCallback,
		);

		return unSub;
	}, [tg, tg?.events, throttledCallback, showTelegramLoginModal]);

	useEffect(() => {
		const unSub = tg?.events.subscribe(
			'syncStateChanged',
			(state: TGSyncState) => {
				dispatch(setSyncState(state.isSynced));
			},
		);

		return unSub;
	}, [tg?.events]);

	useEffect(() => {
		const unSub = tg?.events.subscribe('loggedOut', () => {
			if (!accessToken || !refreshToken) {
				return;
			}
			logoutMutation({
				accessToken,
				refreshToken,
			});
		});

		return unSub;
	}, [tg?.events, accessToken, refreshToken]);

	useInterval(
		() => {
			statusCheckCount.current = statusCheckCount.current + 1;
			// Do not rewrite to async
			tg?.status.proxy.check().then(res => {
				if (res) {
					setStatusConnected(true);
				}
			});

			if (statusCheckCount.current >= 10) {
				setStatusNotSucceeded(true);
			}
		},
		!statusNotSucceeded && !statusConnected ? 1000 : null,
	);

	const onReload = () => {
		setStatusNotSucceeded(false);
		statusCheckCount.current = 0;
	};

	const getWidth = useCallback(() => {
		switch (true) {
			case showTelegramLoginModal:
				return '400px';
			case !!isChat:
				return '100%';

			default:
				return 0;
		}
	}, [isChat, showTelegramLoginModal]);

	return (
		<Box
			zIndex={10}
			flexShrink={showTelegramLoginModal ? 0 : 1}
			width={getWidth()}
			visibility={isChat || showTelegramLoginModal ? 'initial' : 'hidden'}
			height={showTelegramLoginModal ? '680px' : 'auto'}
			boxShadow={
				isChat && cachedData?.hasAccess && !isCardArchived
					? '0px 0px 4px 0px #0000000A'
					: 'unset'
			}
			position="relative"
			sx={{
				...(isChat && (!cachedData?.hasAccess || isCardArchived)
					? limitedAccessCardsBoxStyles
					: {}),
			}}>
			{statusNotSucceeded ? (
				<ErrorPlaceholder onReload={onReload} />
			) : (
				<iframe
					id="telegram-iframe"
					height="100%"
					width="100%"
					allow="clipboard-read; clipboard-write"
					style={{
						borderRadius: '4px',
						border: `1px solid ${colors.gray[20]}`,
					}}
					src={TG_CONFIG.TG_IFRAME_URL}></iframe>
			)}
		</Box>
	);
};
