export const TEAM_CHAT_TABS = [
	{
		label: 'Tasks',
		iconName: 'checkbox',
	},
];

export const CHAT_TABS = [
	{
		label: 'Chat',
		iconName: 'notes',
	},
	{
		label: 'Activity',
		iconName: 'activity',
	},
	{
		label: 'Templates',
		iconName: 'template',
	},
	...TEAM_CHAT_TABS,
	{
		label: 'Reminders',
		iconName: 'calendar',
	},
];
