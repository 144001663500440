import { BoardCardItemResponse } from '../pages/Dashboard/types';
import { CardDefaultStatusEnum, TgDataForCard } from '../types';

export const getUnreadCards = (
	cards: BoardCardItemResponse[],
	tgChats: Record<string, TgDataForCard>,
	statusId?: number,
) =>
	cards.filter(elem => {
		const tgChat = tgChats[elem.chatTelegramId];
		const defaultResponse =
			tgChat?.unreadCount &&
			elem.status.typeId !== CardDefaultStatusEnum.ARCHIVE;
		const responsePerStatus =
			statusId === elem.status.id && defaultResponse;

		return statusId ? responsePerStatus : defaultResponse;
	});

export const getUnansweredCards = (
	cards: BoardCardItemResponse[],
	tgChats: Record<string, TgDataForCard>,
	teamTelegramIds: number[],
	statusId?: number,
) =>
	cards.filter(elem => {
		const tgChat = tgChats[elem.chatTelegramId];
		const isLastMessageFromTeam = teamTelegramIds.includes(
			tgChat?.sender?.id || 0,
		);

		const defaultResponse =
			tgChat?.hasAccess &&
			!isLastMessageFromTeam &&
			elem.trackedLastTelegramMessageUnixTime !==
				tgChat?.lastMessage?.date &&
			elem.status.typeId !== CardDefaultStatusEnum.ARCHIVE;

		const responsePerStatus =
			statusId === elem.status.id && defaultResponse;

		return statusId ? responsePerStatus : defaultResponse;
	});

export const getMentionedCards = (
	cards: BoardCardItemResponse[],
	statusId?: number,
) =>
	cards.filter(card => {
		const defaultResponse =
			card.message?.unreadCount &&
			card.currentUserTagged &&
			card.status.typeId !== CardDefaultStatusEnum.ARCHIVE;

		const responsePerStatus =
			statusId === card.status.id && defaultResponse;

		return statusId ? responsePerStatus : defaultResponse;
	});
