import { useQuery } from '@tanstack/react-query';
import API from '../api/api.base';
import { ApiResponse, CurrentUser } from '../types';
import { QueryKeys } from '../constants';

export const useGetCurrentUser = (isEnabled: boolean) => {
	return useQuery<ApiResponse<CurrentUser>>({
		queryKey: [QueryKeys.GET_CURRENT_USER],
		queryFn: () => API.get('/api/users/current', {}),
		refetchOnWindowFocus: false,
		enabled: isEnabled,
	});
};
