import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../redux/createStore';
import { useTelegram } from '../services';
import { setAvatar } from '../redux/reducers/avatars.reducer';
import { fetchTgChat } from '../services/telegram/utils';
import { useQueryClient } from '@tanstack/react-query';

export const useChatAvatars = (ids?: number[]) => {
	const dispatch = useDispatch();
	const tg = useTelegram();
	const queryClient = useQueryClient();

	const avatars = useSelector((state: RootStore) => state.avatars);

	useEffect(() => {
		if (ids?.length && tg) {
			ids.forEach(async id => {
				if (!avatars[id] && !avatars[id]?.length) {
					try {
						const chatInfo = await fetchTgChat(queryClient, tg, id);
						if (!chatInfo) {
							return dispatch(
								setAvatar({
									[id]: '',
								}),
							);
						}
						dispatch(
							setAvatar({
								[id]:
									chatInfo.chatFullInfo?.profilePhoto
										?.thumbnail?.dataUri || '',
							}),
						);
					} catch (error) {
						dispatch(
							setAvatar({
								[id]: '',
							}),
						);
					}
				}
			});
		}
	}, [ids, tg]);
};
