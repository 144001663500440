import { useQuery } from '@tanstack/react-query';
import API from '../api/api.base';
import { QueryKeys } from '../constants';
import { ApiResponse, WorkspaceSelectItem } from '../types';
import { useSelector } from 'react-redux';
import { RootStore } from '../redux/createStore';

export const useGetWorkspaces = () => {
	const { accessToken } = useSelector((state: RootStore) => state.auth.token);

	return useQuery<ApiResponse<WorkspaceSelectItem[] | null>>({
		queryKey: [QueryKeys.GET_WORKSPACES],
		queryFn: () => API.get('/api/workspaces', {}),
		refetchOnWindowFocus: false,
		enabled: !!accessToken,
	});
};
