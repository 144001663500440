import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import API from '../api/api.base';
import { ApiResponse } from '../types';
import { QueryKeys } from '../constants';
import { RootStore } from '../redux/createStore';
import { useGetMatchedWorkspaceId } from '../hooks';

export const useGetUserTasksCount = (includeRefetchInterval: boolean) => {
	const workspaceId = useGetMatchedWorkspaceId();
	const { isOnboarded } = useSelector((state: RootStore) => state.auth.user);

	return useQuery<ApiResponse<number>>({
		queryKey: [QueryKeys.GET_USER_TASKS_COUNT, workspaceId],
		queryFn: () =>
			API.get(`/api/workspaces/${workspaceId}/tasks-screen/count`, {}),
		refetchOnWindowFocus: false,
		enabled: !!workspaceId && isOnboarded,
		refetchInterval: includeRefetchInterval ? 1000 * 30 * 1 : false, //refetch data every 30s
	});
};
