import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import API from '../api/api.base';
import { ApiResponse, TgFoldersResponseItem } from '../types';
import { QueryKeys } from '../constants';
import { RootStore } from '../redux/createStore';
import { useGetWorkspaceId } from '../hooks';

export const useGetTgFolders = () => {
	const workspaceId = useGetWorkspaceId();

	const { isOnboarded } = useSelector((state: RootStore) => state.auth.user);
	return useQuery<ApiResponse<{ folders: TgFoldersResponseItem[] }>>({
		queryKey: [QueryKeys.GET_TG_FOLDERS, workspaceId],
		queryFn: () =>
			API.get('/api/users/folders', { workspaceId: workspaceId }),
		refetchOnWindowFocus: false,
		enabled: isOnboarded,
	});
};
