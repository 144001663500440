import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExchangeResponse, CurrentUser, RefreshResponse } from '../../types';

const initialState: ExchangeResponse = {
	token: { accessToken: '', accessTokenExpiration: '', refreshToken: '' },
	user: {
		avatarThumbnailLink: null,
		avatarUrl: null,
		firstName: '',
		id: 0,
		telegramUserId: 0,
		lastName: null,
		workSpace: null,
		isOnboarded: true,
		timeZoneId: null,
	},
};

const authReducer = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAuthStatus: (_state, action: PayloadAction<ExchangeResponse>) => {
			return action.payload;
		},
		setWorkspaceId: (state, action: PayloadAction<number>) => {
			state.user.workSpace = {
				id: action.payload,
				name: '',
				avatarUrl: null,
				avatarThumbnailUrl: null,
				teamChat: null,
				isArchiveVisible: true,
				isAdmin: true,
			};
		},
		setUserTimeZone: (state, action: PayloadAction<string>) => {
			state.user.timeZoneId = action.payload;
		},
		refreshToken: (state, action: PayloadAction<RefreshResponse>) => {
			state.token = action.payload;
		},
		setAuthUser: (state, action: PayloadAction<CurrentUser>) => {
			state.user = action.payload;
		},
		setArchiveVisibility: (state, action: PayloadAction<boolean>) => {
			if (state.user.workSpace) {
				state.user.workSpace.isArchiveVisible = action.payload;
			}
		},
		setOnboardedStatus: (state, action: PayloadAction<boolean>) => {
			state.user.isOnboarded = action.payload;
		},
		logout: () => {
			return initialState;
		},
	},
});

export const {
	setAuthStatus,
	logout,
	setWorkspaceId,
	refreshToken,
	setAuthUser,
	setArchiveVisibility,
	setOnboardedStatus,
	setUserTimeZone,
} = authReducer.actions;

export default authReducer.reducer;
