import React, { ReactNode } from 'react';
import {
	Flex,
	FormControl,
	FormControlProps,
	FormErrorMessage,
	FormErrorMessageProps,
	FormLabel,
	FormLabelProps,
	Input,
	InputGroup,
	InputLeftElement,
	InputLeftElementProps,
	InputProps,
	InputRightAddonProps,
	InputRightElement,
} from '@chakra-ui/react';

interface IInputProps extends InputProps {
	label?: string;
	errorMsg?: string;
	formControlProps?: FormControlProps;
	formLabelProps?: FormLabelProps;
	leftIcon?: React.ReactNode;
	rightIcon?: React.ReactNode;
	rightAddon?: React.ReactNode;
	rightAddonProps?: InputRightAddonProps;
	onClickRightIcon?: () => void;
	formErrorMessageProps?: FormErrorMessageProps;
	inputLeftElementProps?: InputLeftElementProps;
	rightLabelElement?: ReactNode;
}

export const InputText = React.forwardRef<null, IInputProps>(
	(
		{
			errorMsg,
			label,
			leftIcon,
			rightIcon,
			formControlProps,
			formLabelProps,
			onClickRightIcon,
			formErrorMessageProps,
			inputLeftElementProps,
			rightLabelElement,
			rightAddon,
			rightAddonProps,
			...rest
		},
		ref,
	) => {
		return (
			<FormControl isInvalid={!!errorMsg} {...formControlProps}>
				{label ? (
					<Flex align={'center'} justify={'space-between'}>
						<FormLabel
							color={'gray.35'}
							fontSize="11px"
							lineHeight="20px"
							mb={'5px'}
							{...formLabelProps}>
							{label}
						</FormLabel>
						{rightLabelElement ? rightLabelElement : null}
					</Flex>
				) : null}
				<InputGroup mt={'0px'}>
					{leftIcon ? (
						<InputLeftElement h={'100%'} {...inputLeftElementProps}>
							{leftIcon}
						</InputLeftElement>
					) : null}
					<Input
						type={'text'}
						w={'full'}
						height="32px"
						background={'#00000005'}
						fontSize={'13px'}
						borderRadius={'4px'}
						lineHeight="20px"
						color="primary"
						_placeholder={{
							color: 'gray.30',
						}}
						border="1px solid"
						borderColor="gray.20"
						{...rest}
						ref={ref}
					/>
					{rightIcon ? (
						<InputRightElement
							onClick={onClickRightIcon}
							cursor={'pointer'}
							h={'100%'}>
							{rightIcon}
						</InputRightElement>
					) : null}
				</InputGroup>
				<FormErrorMessage {...formErrorMessageProps}>
					{errorMsg}
				</FormErrorMessage>
			</FormControl>
		);
	},
);
InputText.displayName = 'InputText';
