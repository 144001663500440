import { useQuery } from '@tanstack/react-query';
import API from '../../../api/api.base';
import { ApiResponse } from '../../../types';
import { ChatTasksListResponse } from '../types';
import { QueryKeys } from '../../../constants';

export const useGetCardTasks = (workspaceId?: number, cardId?: number) => {
	return useQuery<ApiResponse<ChatTasksListResponse>>({
		queryKey: [QueryKeys.GET_CARD_TASKS, cardId, workspaceId],
		queryFn: () =>
			API.get(
				`/api/workspaces/${workspaceId}/cards/${cardId}/tasks/get-list`,
				{},
			),
		refetchOnWindowFocus: false,
		enabled: !!workspaceId && !!cardId,
	});
};
