import React, { useMemo } from 'react';
import {
	Flex,
	Text,
	HStack,
	Box,
	Button,
	useDisclosure,
	IconButton,
} from '@chakra-ui/react';
import { RadixCheckbox } from '../RadixCheckbox/RadixCheckbox';
import { CardTaskItem } from '../../pages/Chat/types';
import { LabelWithImage } from '../LabelWithImage/LabelWithImage';
import {
	convertBase64ToBlob,
	getFormattedViewDate,
	getPriorityStatusById,
	getAvatarBg,
} from '../../utils';
import { CustomTag } from '../CustomTag/CustomTag';
import { CardTaskStatusEnum, TgDataForCard } from '../../types';
import { ActionsMenu } from '../ActionsMenu/ActionsMenu';
import { useDeleteTask } from '../../queries';
import { TaskPopup } from '../../pages/Chat/components';
import { Icon } from '../Icon/Icon';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';
import { useGetFullChatInfo, useTeamMembersList } from '../../hooks';

interface TaskProps extends CardTaskItem {
	onUpdateTaskStatus?: (status: number, telegramUsersIds: number[]) => void;
	userAvatarUri?: string;
	isTaskPage?: boolean;
	cardId?: number;
	chatTelegramId?: number;
	includeActionsMenu?: boolean;
	cachedTgChatData?: TgDataForCard;
}

export const Task: React.FC<TaskProps> = ({
	createdAt,
	priority,
	taskDescription,
	workspaceCard,
	userAvatarUri,
	firstName,
	lastName,
	telegramUserId,
	chatTelegramId,
	id,
	status,
	onUpdateTaskStatus,
	userId,
	cardId,
	remindOn,
	cachedTgChatData,
	// authorUserId,
	includeActionsMenu,
	isTaskPage = false,
}) => {
	const {
		onToggle: onToggleTaskPopup,
		isOpen: isTaskPopupOpen,
		onClose: onCloseTaskPopup,
	} = useDisclosure();
	const deleteTaskMutation = useDeleteTask(isTaskPage, cardId);

	const { chatTeamMemberIds } = useTeamMembersList(chatTelegramId || 0);

	const { id: currentUserId } = useSelector(
		(state: RootStore) => state.auth.user,
	);

	const { time: taskTime, date: taskDate } = getFormattedViewDate(createdAt);

	const priorityData = getPriorityStatusById(priority.id);

	const onChangeStatusClick = (
		status: number,
		telegramUsersIds: number[],
	) => {
		if (status === CardTaskStatusEnum.TODO) {
			return onUpdateTaskStatus?.(
				CardTaskStatusEnum.DONE,
				telegramUsersIds,
			);
		}

		onUpdateTaskStatus?.(CardTaskStatusEnum.TODO, telegramUsersIds);
	};

	const chatAvatarUri = useGetFullChatInfo({
		accessHash: cachedTgChatData?.accessHash,
		type: cachedTgChatData?.type,
		chatId: chatTelegramId,
		title: cachedTgChatData?.title || '',
		msgSenderId: cachedTgChatData?.sender?.id,
		isEnabled:
			!!cachedTgChatData?.hasAccess &&
			!cachedTgChatData?.isChatFullyLoaded,
	});

	const userAvatarUrl = useMemo(
		() =>
			userAvatarUri
				? URL.createObjectURL(convertBase64ToBlob(userAvatarUri))
				: '',
		[userAvatarUri],
	);

	const chatAvatarUrl = useMemo(
		() =>
			chatAvatarUri
				? URL.createObjectURL(convertBase64ToBlob(chatAvatarUri))
				: '',
		[chatAvatarUri],
	);

	const onDeleteTask = () =>
		deleteTaskMutation.mutate(id, {
			onSuccess: data => {
				if (data?.success && isTaskPopupOpen) {
					onCloseTaskPopup();
				}
			},
		});
	return (
		<Flex
			px={4}
			py="14px"
			alignItems="center"
			justifyContent="space-between"
			_last={{ borderBottomWidth: 0 }}
			borderBottomWidth="1px"
			borderBottomColor="gray.15"
			columnGap={2}
			position="relative"
			overflow="hidden"
			_hover={{
				'.actions-menu': {
					opacity: includeActionsMenu ? 1 : 0,
					visibility: includeActionsMenu ? 'visible' : 'hidden',
					transform: includeActionsMenu
						? 'translateY(-50%) translateX(0)'
						: 'translateY(-50%) translateX(100%)',
				},
			}}>
			<HStack spacing={2}>
				{firstName ? null : (
					<Box w="16px" minW="16px" h="16px">
						<RadixCheckbox
							value={status}
							checked={status === CardTaskStatusEnum.DONE}
							onClick={() =>
								onChangeStatusClick(status, chatTeamMemberIds)
							}
						/>
					</Box>
				)}
				<Text
					color="primary"
					fontSize="13px"
					fontWeight="500"
					noOfLines={1}>
					{taskDescription}
				</Text>
			</HStack>
			<HStack align="center" justify="flex-end" spacing={2}>
				{workspaceCard ? (
					<Button
						variant="unstyled"
						h="20px"
						//need endpoint response improvements for the future navigation to the chat by clicking on it
						cursor="default"
						// onClick={() => navigate(`/${workspaceId}/chat/${workspaceCard.id}`)}
					>
						<LabelWithImage
							label={workspaceCard.name}
							imageLink={chatAvatarUrl}
							includeAvatar={true}
							avatarProps={{
								bg: getAvatarBg(workspaceCard.id || 0),
								borderRadius: '2px',
							}}
							imageWrapperProps={{
								borderRadius: '2px',
							}}
						/>
					</Button>
				) : null}
				{firstName ? (
					<LabelWithImage
						label={`${firstName} ${lastName || ''}`}
						imageLink={userAvatarUrl}
						includeAvatar={true}
						avatarProps={{
							bg: getAvatarBg(telegramUserId || 0),
						}}
					/>
				) : null}
				<CustomTag
					label={priority.name}
					bgColor={priorityData.bgColor}
					labelColor={priorityData.textColor}
					wrapperProps={{
						borderRadius: '4px',
					}}
				/>
				<Text
					fontSize="13px"
					fontWeight="400"
					color="secondary"
					sx={{
						textWrap: 'nowrap',
					}}>
					{taskTime}
				</Text>
				{taskDate ? (
					<Text
						fontSize="13px"
						fontWeight="400"
						color="secondary"
						sx={{
							textWrap: 'nowrap',
						}}>
						{taskDate}
					</Text>
				) : null}
			</HStack>
			<ActionsMenu
				isOpen={isTaskPopupOpen}
				includeDelete={true}
				deleteButtonProps={{
					isLoading: deleteTaskMutation.isPending,
					onClick: onDeleteTask,
				}}>
				<TaskPopup
					isOpen={isTaskPopupOpen}
					onClose={onCloseTaskPopup}
					onToggle={onToggleTaskPopup}
					currentPriorityId={priority.id}
					currentDescription={taskDescription}
					currentAssignedUserId={userId || currentUserId}
					taskId={id}
					isTaskPage={isTaskPage}
					cardId={cardId}
					chatTelegramId={chatTelegramId}
					reminderDate={remindOn}>
					<IconButton
						aria-label="edit task"
						minW="auto"
						variant="ghost"
						boxSize="24px"
						borderRadius="4px"
						title="Edit task"
						onClick={onToggleTaskPopup}>
						<Icon name="edit" height="16" width="16" />
					</IconButton>
				</TaskPopup>
			</ActionsMenu>
		</Flex>
	);
};
